/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {

					/**
					 * Load Gallery Lightboxes
					 */

					$('a.fl-gallery').featherlightGallery({
						previousIcon: '&#9664;',     /* Code that is used as previous icon */
						nextIcon: '&#9654;',         /* Code that is used as next icon */
						galleryFadeIn: 100,          /* fadeIn speed when slide is loaded */
						galleryFadeOut: 300          /* fadeOut speed before slide is loaded */
					});

					$('.pb--slider').owlCarousel({
						items: 1,
						nav: true
				});

				var html = $('html');

				$('.header--menu').on('click', function() {
						html.removeClass('loading').toggleClass('nav-open');
				});

				$('.nav-mobile-primary-close--wrapper').on('click', function() {
						html.removeClass('nav-open');
				});

				$('#menu-mobile-menu').children('.menu-item-has-children').on('click', function (e) {
					if ( $(e.currentTarget).hasClass('opened') === false ) {
						e.preventDefault();
						$(e.currentTarget).toggleClass('opened');
					} else {
						$(e.currentTarget).toggleClass('opened');
					}
				});

			},
			finalize: function() {

				$('#category-select').on('change', function(e){
					console.log(this.value);
					if( this.value ) {
						var getUrl = window.location;
						window.location.replace(getUrl .protocol + "//" + getUrl.host + "/" + this.value);
					} else {
						window.location.replace(getUrl .protocol + "//" + getUrl.host);
					}
				});

				$('.hero-matrix--item').matchHeight({
					byRow: false,
					property: 'height',
				});
				// JavaScript to be fired on all pages, after page specific JS is fired
				$('.slider-wrapper').slick({
					infinite: true,
					slidesToShow: 5,
					slidesToScroll: 1,
					nextArrow: $('.next-control'), 
					prevArrow: $('.prev-control'),
					responsive: [
						{
							breakpoint: 1024,
							settings: {
								slidesToShow: 4,
							}
						},
						{
							breakpoint: 991,
							settings: {
								slidesToShow: 3,
							}
						},
						{
							breakpoint: 786,
							settings: {
								slidesToShow: 1,
							}
						},
					]
				});
				

				$('.slider-wrapper--testimonial').slick({
					infinite: true,
					slidesToShow: 3,
					slidesToScroll: 1,
					nextArrow: $('.next-control'),
					prevArrow: $('.prev-control'),
					responsive: [
						{
							breakpoint: 786,
							settings: {
								slidesToShow: 1,
							}
						},
					]
				});
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function () {
				
				$('.home-body-listing--item').matchHeight({
					byRow: false,
					property: 'height',
				});
				$('.home-body-listing--item__excerpt').matchHeight({
					byRow: false,
					property: 'height',
				});
				// $('.home-body-listing--item__heading').matchHeight({
				//   byRow: false,
				//   property: 'height',
				// });

				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about': {
			init: function() {
				// JavaScript to be fired on the about us page
			},
			finalize: function () {
				
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
